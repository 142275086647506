import React, { useState, useEffect, useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
import moment from 'moment';

const Pan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const printRef = useRef();
    const formRef = useRef(null);
    const [rc, setRc] = useState("");
    const [rcDetails, setRcDetails] = useState();
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setRc("");
        setRcDetails(null);
    }

    const rcFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "request_rc_number":rc
        };
        CommonService.rcSearchBlack(data)
        .then((response) => {
            if(response.data.status === 200){
                if(response.data.data.status == 0){
                    Swal.fire({
                        icon: 'error',
                        title: response.data.data.statusCode,
                        text: response.data.data.msg,
                    })
                    setIsLoading(false);
                    setRcDetails(null);
                    
                }else{
                    setRcDetails(response.data.data);
                    setIsLoading(false);
                }
                
            }else{
                
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setRcDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
           
         
        });
    }

    const handlePrint = () => {
        const originalContents = document.body.innerHTML;
        const printContents = printRef.current.innerHTML; 
        document.body.innerHTML = printContents; 
        window.print(); 
        document.body.innerHTML = originalContents; 
        //window.location.reload();
        
    };
    const clientID = localStorage.getItem('id');
    console.log('clientID', clientID);
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">RC Blacklist Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ rcFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Rc No.</label>
                                                        <input type="text" defaultValue={rc}
                                                            onChange={(e) => { setRc(e.target.value.toUpperCase()) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter rc number"
                                                         />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { rcDetails ? (
                                                <>
                                                {(clientID == 6 || clientID == 7 || clientID == 1) && (
                                                    <div className="row">
                                                        <div className="col-md-7"></div>
                                                        <div className="col-md-5">
                                                            <div className="card-header" style={{ float: "right" }}>
                                                                <button
                                                                    onClick={handlePrint}
                                                                    className="btn btn-primary"
                                                                    style={{ textDecoration: "none" }}
                                                                >
                                                                    <i className="ti ti-printer"></i> &nbsp;Print
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row" ref={printRef}>
                                                    <div className="col-md-12">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i> RC BLACK LIST DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body" style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>RC Registration Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.rcNumber ? rcDetails.rcNumber : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i> RC STATUS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <b>Blacklist Status</b> 
                                                                </div>
                                                                <div className="col-md-8" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.blacklistStatus ? rcDetails.data.blacklistStatus : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp;  API HEALTH CHECK
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.totalTime}
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                             </>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Pan;