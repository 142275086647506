import React from "react";
import { Link } from "react-router-dom";

const Footer = () =>{
    const currentYear = new Date().getFullYear();
   
    return (
        <footer className="content-footer footer bg-footer-theme">
            <div className="container-xxl">
                <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                <div>
                    © {currentYear}, made with ❤️ by <Link to="http://www.wheelsanalytics.com/" target="_blank" className="fw-medium">Wheel's Analytics Infotech Pvt Ltd</Link>
                </div>
                {/* <div className="d-none d-lg-inline-block">
                    
                    <Link to="#" className="footer-link me-4" target="_blank">License</Link>
                    <Link to="#" target="_blank" className="footer-link me-4">More Themes</Link>
                    
                    <Link to="#" target="_blank" className="footer-link me-4">Documentation</Link>
                    
                    
                    <Link to="#" target="_blank" className="footer-link d-none d-sm-inline-block">Support</Link>
                    
                </div> */}
                </div>
            </div>
        </footer>
        
    );
}

export default Footer;